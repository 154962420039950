<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill="#6DD400"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10z"
    />
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M8.676 11.382l4.943-3.705a1.111 1.111 0 1 1 1.333 1.778l-5.714 4.283a1.111 1.111 0 0 1-1.452-.103l-2.857-2.856a1.111 1.111 0 1 1 1.57-1.571l2.177 2.174z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
